import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "typescript"
    }}>{`TypeScript`}</h1>
    {
      /*
      TODO: this feels like it’s placed weirdly.
      Where could it go?
      */
    }
    <p>{`If you’re getting errors from TypeScript related to imports with an `}<inlineCode parentName="p">{`*.mdx`}</inlineCode>{`
extension, create an `}<inlineCode parentName="p">{`mdx.d.ts`}</inlineCode>{` file in your types directory and include it
inside your `}<inlineCode parentName="p">{`tsconfig.json`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// types/mdx.d.ts
declare module '*.mdx' {
  let MDXComponent: (props) => JSX.Element;
  export default MDXComponent;
}
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      